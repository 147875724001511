<template>
  <form action="#" @submit.prevent="handleSubmit">
    <div v-if="!inline" class="common-modal-headline">
      <h4 class="common-modal-title">
        {{
          isNew
            ? $t("page.company.cafes-create")
            : $t("page.company.Редагувати новину")
        }}
      </h4>

      <span
        class="material-symbols-rounded common-modal-close"
        @click="$emit('onClose')"
      >
        close
      </span>
    </div>

    <hr v-if="!inline" class="my-3" />

    <div class="row">
      <div class="col-md-6 mx-auto">
        <b-alert
          variant="danger"
          dismissible
          class="mt-3"
          :show="Boolean(error)"
          >{{ error }}</b-alert
        >
      </div>
    </div>

    <!-- pictire -->
    <div class="row">
      <div class="col-md-6">
        <div class="form-group mb-3">
          <label :class="{ required: isNew }">{{ $t("table.photo") }}</label>
          <div class="input-group input-group-merge">
            <div v-if="form.picture">
              <div class="picture-holder">
                <img :src="form.picture" />
                <span
                  @click="removePicture"
                  :title="$t('btn.remove')"
                  class="material-symbols-rounded fz-24 icon-action-remove"
                  >close</span
                >
              </div>
            </div>
            <input
              v-show="!form.picture"
              ref="input-file-picture"
              type="file"
              accept="image/png, image/jpeg, image/jpg, image/bmp"
              class="form-control"
              :class="{
                'is-invalid': isNew && submitted && $v.form.picture.$error,
              }"
              @change="uploadPicture"
            />

            <div
              v-if="isNew && submitted && $v.form.picture.$error"
              class="invalid-feedback"
            >
              {{ $t("form.password-req") }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- address -->
    <div class="row">
      <div class="col-md-6">
        <div class="form-group mb-3">
          <label class="required">{{ $t("table.address") }}</label>
          <div class="input-group input-group-merge">
            <input
              v-model="form.address"
              class="form-control"
              :placeholder="$t('table.address')"
              :class="{
                'is-invalid': submitted && $v.form.address.$error,
              }"
            />
            <div
              v-if="submitted && $v.form.address.$error"
              class="invalid-feedback"
            >
              {{ $t("form.field-req") }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr v-if="!inline" class="mt-2 mb-1" />

    <div class="mt-3 text-center text-md-left">
      <button class="btn btn-primary" type="submit" :disabled="trySubmit">
        {{ isNew ? $t("btn.add-cafes") : $t("btn.save-changes") }}
      </button>
    </div>

    <!-- <pre>cafes: {{ cafes }}</pre> -->
    <!-- <pre>form: {{ form }}</pre> -->
  </form>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useGlobalStore } from "@/store/global";
import Editor from "@tinymce/tinymce-vue";
import { required, minLength, maxLength } from "vuelidate/lib/validators";

export default {
  components: {
    Editor,
  },
  props: {
    cafes: {
      type: Object,
      default: () => {},
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      error: "",

      form: {
        address: "",
        picture: "",
      },

      submitted: false,
      trySubmit: false,
    };
  },
  validations() {
    return {
      form: this.formRules,
    };
  },
  created() {
    this.initForm();
  },
  computed: {
    isNew() {
      return !this.cafes?.id;
    },

    formRules() {
      let rules = {};

      rules.address = {
        required,
        // minLength: minLength(5),
        // maxLength: maxLength(64),
      };

      if (this.isNew) {
        rules.picture = {
          required,
        };
      }

      return rules;
    },
  },
  methods: {
    ...mapActions(useGlobalStore, ["updateCafes", "createCafes"]),
    initForm() {
      if (!this.isNew) {
        this.form = {
          id: this.cafes.id,
          address: this.cafes.address,
          picture: this.cafes.picture,
        };
      }
    },
    async handleSubmit() {
      this.submitted = true;
      this.error = "";

      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        try {
          this.trySubmit = true;

          if (this.isNew) {
            const formData = new FormData();

            // form
            formData.append("address", this.form.address);
            if (this.$refs["input-file-picture"]?.files[0]) {
              formData.append(
                "picture",
                this.$refs["input-file-picture"].files[0]
              );
            }

            await this.createCafes(formData);
            this.$emit("onSubmit", this.$t("msg.cafes-create-success"));
          } else {
            const formData = new FormData();

            // form
            if (this.form.address) {
              formData.append("address", this.form.address);
            }

            if (this.$refs["input-file-picture"]?.files[0]) {
              formData.append(
                "picture",
                this.$refs["input-file-picture"].files[0]
              );
            }

            await this.updateCafes(this.form.id, formData);
            this.$emit("onSubmit", this.$t("msg.data-update-success"));
          }
        } catch (error) {
          console.log("handleSubmit, error: ", error);
          this.error = this.$t("msg.error");

          if (error.response?.status && error.response?.data?.message) {
            this.error = `[${error.response.status}] ${error.response.data.message}`;
          }
        } finally {
          this.$helpers.scrollTo();
          this.trySubmit = false;
        }
      }
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    async uploadPicture(e) {
      const file = e.target.files[0];

      if (file) {
        const base64 = await this.getBase64(file);
        this.form.picture = base64;
      }
    },
    removePicture() {
      this.$refs["input-file-picture"].value = null;
      this.form.picture = "";
    },
  },
  watch: {},
};
</script>
<style scoped lang="scss">
.picture-holder {
  display: inline-block;
  position: relative;
  font-size: 0;
  line-height: 0;

  img {
    // max-width: 400px;
    max-width: 100%;
    width: 100%;
    height: auto;
  }

  .icon-action-remove {
    cursor: pointer;
    position: absolute;
    top: -10px;
    right: -30px;
    font-size: 22px;

    &:hover {
      color: red;
    }
  }
}
</style>
