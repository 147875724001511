<template>
  <Layout>
    <div class="mb-3">
      <h1 class="display-3 mb-3">
        {{ title }}
      </h1>
    </div>

    <!-- <div>
      <pre>id: {{ id }}</pre>
    </div> -->
    <!-- <div>
      <pre>cafes: {{ cafes }}</pre>
    </div> -->
    <!-- <div>
      <pre>alert: {{ alert }}</pre>
    </div> -->

    <div class="mb-3">
      <div v-if="loading">
        <div class="text-center">
          <b-spinner variant="primary" />
        </div>
      </div>

      <div v-else class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6 mx-auto">
              <b-alert
                v-if="alert"
                :variant="alert?.status"
                class="mt-3"
                dismissible
                :show="Boolean(alert)"
                >{{ alert?.text }}</b-alert
              >
            </div>
          </div>

          <formCafes :cafes="cafes" @onSubmit="submitForm" inline />
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useGlobalStore } from "@/store/global";
import Layout from "@/views/layouts/main";
import formCafes from "@/components/company/form-cafes";

export default {
  metaInfo() {
    return {
      title: this.$t("app.title") + " | " + this.title,
    };
  },
  components: {
    Layout,
    formCafes,
  },
  data() {
    return {
      loading: false,
      alert: null,
      wait: false,
      cafes: null,
    };
  },
  created() {
    if (this.id) {
      this.loadData(false);
    }
  },
  computed: {
    id() {
      return this.$route.params?.id || null;
    },
    title() {
      if (this.id) {
        if (this.cafes) {
          return `${this.$t("page.company.cafes-edit")}: ${this.cafes.name}`;
        } else {
          return this.$t("page.company.cafes-edit");
        }
      }

      return this.$t("page.company.cafes-create");
    },
  },
  methods: {
    ...mapActions(useGlobalStore, ["getCafes"]),
    async loadData(silent = true) {
      if (this.wait) {
        return;
      }
      this.wait = true;
      if (!silent) {
        this.loading = true;
      }
      this.alert = null;

      try {
        const req = await this.getCafes(this.id);
        console.log("loadData, req: ", req);
        this.cafes = req.data;
      } catch (error) {
        this.alert = {
          status: "danger",
          text: this.$t("msg.error"),
        };
        if (error.response?.status && error.response?.data?.message) {
          this.alert.text = `[${error.response.status}] ${error.response.data.message}`;
        }
      } finally {
        this.loading = false;
        this.wait = false;
      }
    },
    async submitForm(text) {
      // await this.loadData();

      this.alert = {
        status: "success",
        text,
      };
    },
  },
};
</script>
<style scoped lang="scss"></style>
